import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Link } from "gatsby";
import { FaMicrosoft } from "react-icons/fa";

const Investor = () => (
  <React.Fragment>
    <Header />
    <section className="product-section">
      <div className="container-fluid all-section">
        <div className="row">
          <div className="col-md-4 col-lg-3">
            <div className="heading">
              <h2>Investor</h2>
            </div>
            <div className="product-menu">
              <ul>
                <li>
                  <Link to="/developer">Developers</Link>
                </li>
                <li>
                  <Link to="/builders">Builders</Link>
                </li>
                <li>
                  <Link to="/architect-designer">Architects & Designers</Link>
                </li>
                <li>
                  <Link to="/investor">Investors</Link>
                </li>
              </ul>
              <div className="links-list sidebar-links">
                <Link to="/service" className="links-list-item">
                  <FaMicrosoft />
                  &nbsp; Go to services
                </Link>
              </div>
            </div>
          </div>

          <div className="col-md-8 col-lg-9 product-item mt-2">
            <div className="col-12 col-md-12 mb-4">
              <div className="pro-start-content">
                <div className="product-inner">
                  <p className="text-justify">
                    Need to lift the value of an investment property? Our
                    solutions are used extensively by major apartment
                    developers, medium density project builders and developers,
                    and leading volume builders Australia wide.
                  </p>
                  <p className="text-justify">
                    With a commitment to product durability and affordability,
                    we’re confident we can help you find the right choice for
                    your project.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-xl-12">
              <div className="about-form">
                <form>
                  <div className="form-group">
                    <label htmlFor="about-company" className="font-weight-bold">
                      Company<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="about-company"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="about-First" className="font-weight-bold">
                      First Name<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="about-First"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="about-Last" className="font-weight-bold">
                      Last Name<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="about-Last"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="about-Email" className="font-weight-bold">
                      Email<span className="text-danger">*</span>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="about-Email"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="about-Help" className="font-weight-bold">
                      How Can We Help?<span className="text-danger">*</span>
                    </label>
                    <textarea
                      className="form-control"
                      id="about-Help"
                      rows="5"
                    ></textarea>
                  </div>

                  <button type="submit" className="btn btn-dark text-uppercase">
                    submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Footer />
  </React.Fragment>
);
export default Investor;
